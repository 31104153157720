/* 
 * Copyright 2021 LetterBlock LLC. All rights reserved. 
 * http://letterblock.com/ 
 * 
 * Permission to use, copy, modify, and/or distribute this software for any 
 * purpose with or without fee is hereby granted.
 * 
 * THE SOFTWARE IS PROVIDED "AS IS" AND THE AUTHOR DISCLAIMS ALL WARRANTIES WITH
 * REGARD TO THIS SOFTWARE INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY 
 * AND FITNESS. IN NO EVENT SHALL THE AUTHOR BE LIABLE FOR ANY SPECIAL, DIRECT, 
 * INDIRECT, OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM 
 * LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR
 * OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR 
 * PERFORMANCE OF THIS SOFTWARE.
 *
 * Usage
 * =====
 * 
 * onElementExists(selector, function(element) {
 *   ...do something that depends on the element existing...
 *   return fnOnElementRemoved
 * })
 * 
 * The function in the second argument will be called for each element matching
 * the selector, whether the element already exists when onElementExists is 
 * called or is added to the DOM later.
 * 
 * If the function returns a function, it will be called if and when the element
 * is removed from the DOM. Use this to clean up observers, timeouts, etc..
 * 
 * Warning: You should probably not use a selector that will match many times on
 * a given page, and you should definitely not create more elements that match
 * the same selector within your onElementExists callback.
 */

export default (function(){
  const mountFn = fn => target => {
    if (target._unmountFns?.has(fn)) {return true;}
    target._unmountFns = target._unmountFns || new Map();
    target._unmountFns.set(fn, fn(target) || true);
  };
  
  let observer;
  const mountFns = new Map();
  let mountFrame;
  
  const cancelFn = myMountFn => () => {
    mountFns.delete(myMountFn);
    if (0 == mountFns.size && observer){
      observer.disconnect();
      observer = null;
    }
  };
  
  const mountNow = () => {
    for (const [fn, selector] of mountFns.entries()){
      for (const node of document.querySelectorAll(selector)){
        fn(node);
      }
    }
  };
  
  const onElementExists = (selector, fn) => {
    const myMountFn = mountFn(fn);
    mountFns.set(myMountFn, selector);
    if (!observer){
      observer = new MutationObserver(mutations => {
        for (const mutation of mutations){
          for (const [fn, selector] of mountFns.entries()){
            for (const node of mutation.addedNodes) {
              if (!(node instanceof HTMLElement)) continue;
              for (const subNode of node.querySelectorAll(selector)) {
                fn(subNode);
              }
              if (node.matches(selector)) { fn(node); }
            }
          }
          for (const node of mutation.removedNodes) {
            // TODO: My intuition is that these should be called from the bottom up, but this implementation calls them in document order instead
            const tw = document.createTreeWalker(node, NodeFilter.SHOW_ELEMENT);
            let next;
            while (next = tw.nextNode()) {
              if (next._unmountFns) {
                for (const fn of next._unmountFns.values()){
                  if (fn && fn !== true) {fn();}
                }
              }
            }
            if (node._unmountFns){ 
              for (const fn of node._unmountFns.values()){
                if (fn && fn !== true) {fn();}
              }
            }
          }
        }
      });
      requestAnimationFrame(() => observer && observer.observe(document, {
        childList: true,
        subtree: true,
      }));
    }
    if (mountFrame) {cancelAnimationFrame(mountFrame);}
    mountFrame = requestAnimationFrame(mountNow);
    return cancelFn(myMountFn);
  };
  return onElementExists
})()